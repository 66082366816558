import * as React from "react";
import { indigo } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";

const TemplateTypeItem = (props) => {
  // const [isChecked, setIsChecked] = React.useState(props.isChecked);
  const onCheckFilterTypeChange = () => {
    // setIsChecked(!isChecked);
    props.onCheckFilterTypeChanged(!props.isChecked);
  };
  // console.log(props.item)
  const screenWidth = screen.width

  return (
    <div className="flex w-full flex-row md:text-white text-sm font-body" data-test={props['data-test']}>
      <div className="">
        <Checkbox
          className="md:hidden"
          checked={props.isChecked}
          onChange={(e) => {
            onCheckFilterTypeChange();
          }}
          size="small"
          sx={{
            color: '#3B3B3B',
            fontSize: "14px",
            "&.Mui-checked": { color: '#3B3B3B' },
          }}
        />
        <Checkbox
          className="hidden md:inline-flex"
          checked={props.isChecked}
          onChange={(e) => {
            onCheckFilterTypeChange();
          }}
          size="small"
          sx={{
            color: 'white',
            fontSize: "14px",
            "&.Mui-checked": { color: 'white' },
          }}
        />
      </div>
      <div
        className="w-full self-center hover:cursor-pointer text-grey-primary md:text-white"
        onClick={() => onCheckFilterTypeChange()}
      >
        {props.item.category}
      </div>
      <div className="w-8 self-center hidden">{props.item.cnt}</div>
    </div>
  );
};

export default TemplateTypeItem;
