import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Navbar } from "../components/Layout/Navbar";
import NoSiteExists from "../components/Dashboard/NoSiteExists";
import SiteItem from "../components/Dashboard/SiteItem";
import CookieConsent from "../components/CookieConsent/CookieConsent";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Backdrop from "@mui/material/Backdrop";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import axiosInstance from "../axiosInstance";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GoogleAnalytics from "../components/GoogleAnalytics";

export const DashboardPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [showIsDirtySites, setShowIsDirtySites] = useState(false);
  const [sitesData, setSitesData] = useState<any[]>([]);
  const [cookie_userData, setCookie_userData, removeCookie_userData] =
    useCookies(["rp_user_data"]);

  async function fetchData() {
    // let user: any = localStorage.getItem("userData") || "";
    // console.log('fetchData', ,cookie_userData.rp_user_data)
    setIsLoading(true);
    let user: any =
      cookie_userData.rp_user_data ||
      (location.state && location.state.user.data);
    // user = JSON.parse(user);
    if (user) {
      let response = await axiosInstance.get(
        `/api/configs/users-site-configs?userId=${user.userId}`
      );
      setSitesData(response.data.data);
      setIsLoading(false);
      // console.log("response", response.data.data);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const onCreateNewWebsite = () => {
    navigate(`/choose-template`);
  };

  const onSiteRename = async (siteId, newSiteName) => {
    try {
      setIsLoading(true);
      await axiosInstance.post(`/api/configs/append-site-config`, {
        siteId: siteId,
        siteName: newSiteName,
      });

      fetchData();
    } catch (e) {
      console.error("onSiteRename", e);
    } finally {
      setIsLoading(false);
    }
  };
  const onSiteDelete = async (siteId) => {
    try {
      setIsLoading(true);
      await axiosInstance.post(`/api/configs/delete-site`, { siteId: siteId });
      enqueueSnackbar("Site deleted successfully!", {
        variant: "info",
        className: "bg-grey-400",
      });
      fetchData();
    } catch (e) {
      console.error("onSiteDelete", e);
    } finally {
      setIsLoading(false);
    }
  };
  const onSiteDuplicate = async (siteId) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      await axiosInstance.post(`/api/configs/duplicate-site`, {
        siteId: siteId,
      });
      enqueueSnackbar("Site duplicated successfully!", {
        variant: "success",
        className: "bg-grey-400",
      });

      fetchData();
    } catch (e) {
      console.error("onSiteDuplicate", e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>RocketPages - Dashboard</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      {/* Include Google Analytics */}
      <GoogleAnalytics />
      <div className="font-body text-base font-medium">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Navbar {...stateData} title={"My websites"} />
        <div className="bg-gray-100 flex absolute flex-col top-[64px] w-full">
          <div className="w-full">
            {sitesData.length > 0 && (
              <div className="py-3 px-4 flex md:justify-end">
                <Button
                  size="small"
                  variant="contained"
                  sx={{ backgroundColor: "#243060", textTransform: "none" }}
                  startIcon={<AddCircleIcon sx={{ color: "#fff" }} />}
                  onClick={onCreateNewWebsite}
                >
                  Create new website
                </Button>
              </div>
            )}
            <div className="bg-gray-100 min-h-screen p-4">
              {showIsDirtySites && (
                <div className="fixed inset-0 overflow-y-auto flex justify-center items-center z-10 ">
                  <div className="fixed inset-0">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  <div className="bg-[#FAFAFA] rounded-lg p-2 w-11/12 mx-auto z-50 border-2 border-red-600 m-4 absolute lg:top-auto top-20 ">
                    <div className="flex justify-end">
                      <CloseOutlinedIcon
                        className="hover:cursor-pointer absolute"
                        onClick={() => setShowIsDirtySites(false)}
                      />
                    </div>
                    <div className="flex pt-2 px-4 sm:px-2 pb-4 flex-col justify-center text-black items-center">
                      <InfoOutlinedIcon sx={{ color: "red" }} />
                      <span className="text-red-500 font-medium">
                        Actions Required
                      </span>
                      <span className="text-base font-normal text-center">
                        Some of your sites need your immediate attention.
                      </span>

                      <div className="flex w-full mt-4 lg:flex-row flex-col">
                        <div
                          className={`border border-color-red w-full lg:w-1/2 lg:mr-2 mr-0 p-2 mb-2 rounded-md bg-white flex-1 ${
                            sitesData.filter((oo) => oo.isDirty === "true")
                              .length === 0
                              ? "hidden"
                              : ""
                          }`}
                        >
                          <div className="flex md:flex-row flex-col justify-between w-full mt-1">
                            <span className="text-red-500">
                              Sites at Risk of Deletion:
                            </span>
                            <Button
                              size="small"
                              variant="contained"
                              className="md:mt-0 mt-2 mr-4"
                              sx={{
                                backgroundColor: "#243060",
                                textTransform: "none",
                                width: "85px",
                              }}
                              onClick={async () => {
                                setIsLoading(true);
                                let _jjjj: any = [];
                                sitesData
                                  .filter((oo) => oo.isDirty === "true")
                                  .map((oo: any) => _jjjj.push(oo.siteId));
                                let res = await axiosInstance.post(
                                  `/api/configs/retain-sites`,
                                  {
                                    siteIds: _jjjj,
                                  }
                                );
                                if (res.status === 200) {
                                  fetchData();
                                }
                              }}
                            >
                              Save all
                            </Button>
                          </div>
                          <div className="flex flex-col  rounded-md h-8/12 lg:h-80 overflow-y-auto w-full mt-4">
                            {sitesData
                              .filter((oo) => oo.isDirty === "true")
                              .map((itm, _idx) => {
                                return (
                                  <div
                                    className={`flex md:flex-row flex-col md:gap-0 gap-2 justify-between mx-4 py-2 border-b`}
                                    key={itm.siteId}
                                  >
                                    <span>
                                      <span className="mr-4">{_idx + 1}</span>
                                      <span>{itm.siteName}</span>
                                    </span>

                                    <span className="flex">
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                          // backgroundColor: "#243060",
                                          marginRight: "8px",
                                          textTransform: "none",
                                        }}
                                        onClick={async () => {
                                          let res = await axiosInstance.post(
                                            `/api/configs/retain-sites`,
                                            {
                                              siteIds: [itm.siteId],
                                            }
                                          );
                                          if (res.status === 200) {
                                            fetchData();
                                          }
                                        }}
                                      >
                                        Save
                                      </Button>
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                          // backgroundColor: "#243060",
                                          textTransform: "none",
                                        }}
                                        onClick={() => {
                                          let _url = `${process.env.EDITOR_ENDPOINT}/${itm.siteId}`;
                                          window.open(_url, "_self")?.focus();
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        {/*  */}
                        <div
                          className={`border border-color-red w-full lg:w-1/2 lg:ml-2 ml-0 p-2 mb-2 rounded-md bg-white flex-1 ${
                            sitesData.filter((oo) => oo.disabled).length === 0
                              ? "hidden"
                              : ""
                          }`}
                        >
                          <div className="flex flex-row justify-between w-full mt-1">
                            <span className="text-red-500">
                              Sites hit visitor limit and are now disabled.
                              Upgrade to keep live.
                            </span>
                          </div>
                          <div className="flex flex-col  rounded-md h-8/12 lg:h-80 overflow-y-auto w-full mt-4">
                            {sitesData
                              .filter((oo) => oo.disabled)
                              .map((itm, _idx) => {
                                return (
                                  <div
                                    key={itm.siteId}
                                    className={`flex md:flex-row flex-col md:gap-0 gap-2 justify-between mx-4 py-2 border-b`}
                                  >
                                    <span>
                                      <span className="mr-4">{_idx + 1}</span>
                                      <span>{itm.siteName}</span>
                                    </span>

                                    <span>
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                          // backgroundColor: "#243060",
                                          textTransform: "none",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            `/site-settings/${itm.siteId}?section=Subscriptions&tab=pricing`
                                          );
                                        }}
                                      >
                                        Upgrade Plan
                                      </Button>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {sitesData.length === 0 && (
                <NoSiteExists onNewWebsiteClick={onCreateNewWebsite} />
              )}
              {sitesData.length > 0 && (
                <div className="bg-[#FFD970] flex justify-center py-2 text-black mx-4">
                  <InfoOutlinedIcon />
                  <span className="ml-2">
                    Your websites will be deleted if not saved or updated within
                    3 days.
                  </span>
                </div>
              )}
              {/* <NoSiteExists /> */}
              {sitesData.length > 0 && (
                <div className="px-4 grid grid-cols-1 gap-8 py-4 md:grid-cols-2 lg:grid-cols-3">
                  {sitesData.map((item) => {
                    return (
                      <SiteItem
                        key={item.siteId}
                        data={item}
                        sitesData={sitesData}
                        onSettingClick={() => {
                          navigate(`/site-settings/${item.siteId}`);
                        }}
                        onEditSite={() => {
                          window.location.href = `${process.env.EDITOR_ENDPOINT}/${item.siteId}/`;
                          // window.location.reload();
                        }}
                        onSiteRename={(siteId, newSiteName) => {
                          onSiteRename(siteId, newSiteName);
                          // console.log(`Rename ${siteId} to ${newSiteName}`);
                        }}
                        onSiteDelete={(siteId) => {
                          onSiteDelete(siteId);
                          // console.log(`delete ${siteId}`);
                        }}
                        onSiteDuplicate={(siteId) => {
                          onSiteDuplicate(siteId);
                          // console.log(`duplicate ${siteId}`);
                        }}
                        // onMoreOptionClick={() => {}}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CookieConsent />
    </>
  );
};
