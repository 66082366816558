import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

const CategoryItem = (props) => {
  // const [isChecked, setIsChecked] = React.useState(props.isChecked);
  const onCheckChange = () => {
    // setIsChecked(!isChecked);
    props.onCheckChanged(!props.isChecked);
  };
  const screenWidth = screen.width
  return (
    <div className="flex w-full flex-row md:text-white text-sm font-body" data-test={props['data-test']}>
      <div className="">
        <Checkbox
          className="md:hidden"
          checked={props.isChecked}
          onChange={onCheckChange}
          size="small"
          sx={{
            color: '#3B3B3B',
            fontSize: "14px",
            "&.Mui-checked": { color: '#3B3B3B' },
          }}
        />
        <Checkbox
          className="hidden md:inline-flex"
          checked={props.isChecked}
          onChange={(e) => {
            onCheckChange();
          }}
          size="small"
          sx={{
            color: 'white',
            fontSize: "14px",
            "&.Mui-checked": { color: 'white' },
          }}
        />
      </div>
      <div
        className="w-full self-center hover:cursor-pointer text-grey-primary md:text-white"
        onClick={() => onCheckChange()}
      >
        {props.item.category}
      </div>
      <div className="w-8 self-center">{props.item.cnt}</div>
      {/* <div className="w-8 self-center hidden" style={{color: '#3B3B3BCC'}}>{props.item.cnt}</div> */}
    </div>
  );
};

export default CategoryItem;
